import { Award,Shop,Truck,Notepad2,Send ,WalletMoney,MobileProgramming,ReceiptText,ShoppingBag,HomeTrendUp} from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
    {
        header: <IntlMessages id="sidebar-pages" />,
        
    },
    {
        id: "Imoveis",
        title: <IntlMessages id="Gestão de Imoveis" />,
        icon: <Shop size={18} />,
        navLink: "/pages/imoveis",
        permissions: ["corretor"],
        
    },
   
    {
        id: "Administrativo",
        title: <IntlMessages id="Administrativo" />,
        icon: <HomeTrendUp size={18} />,
        navLink: "/pages/Administrativo",
        permissions: ["administrativo"]
    },
    
   
    {
        id: "Pagamentos",
        title: <IntlMessages id="Pagamentos" />,
        icon: <MobileProgramming size={18} />,
        navLink: "/pages/Pagamentos",
        permissions: ["administrativo"]
    },
    
];

export default pages