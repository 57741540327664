import { lazy } from "react";

const PagesRoutes = [
  // Comercial
  {
    path: "/pages/imoveis",
    component: lazy(() => import("../../view/pages/imoveis")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["corretor"],
  },

  {
    path: "/cadastrar/imovel",
    component: lazy(() => import("../../view/pages/imoveis/PropertyRegister")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["corretor"],
  },
  {
    path: "/cadastrar/painel",
    component: lazy(() => import("../../view/pages/imoveis/PropertyPanel")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["corretor"],
  },

  {
    path: "/pages/Pagamentos",
    component: lazy(() => import("../../view/pages/pagamentos/index")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },
  {
    path: "/pages/usuarios",
    component: lazy(() => import("../../view/pages/pagamentos/usuarios")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },
  {
    path: "/pages/contas",
    component: lazy(() => import("../../view/pages/pagamentos/contas")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },
  {
    path: "/pages/Remessa",
    component: lazy(() => import("../../view/pages/pagamentos/GerarRemessa")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },


  // ADM
  {
    path: "/pages/Administrativo",
    component: lazy(() => import("../../view/pages/adm")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },



  // Eroo
  {
    path: "/pages/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },



  // Login

  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication-modern/login")),
    layout: "FullLayout",
  },

  {
    path: "/home",
    component: lazy(() => import("../../view/pages/home/index")),
    layout: "FullLayout",
  },



  //Usuarios

  {
    path: "/personel-information",
    component: lazy(() => import("../../view/pages/profile/personel-information")),
    layout: "VerticalLayout",
    isProtected: true,
  },


];

export default PagesRoutes;