import React, { useEffect, useState } from 'react';
import { Layout, Button, Select, Carousel, message, Modal, Row, Col, Card, Input } from 'antd';
import { useNavigate } from 'react-router-dom'; // Importando o hook useNavigate para navegação
import api from '../../../configs/api'; // Importar a configuração da API

import './App.css';
import logo from '../../../assets/images/logo/3.png'; // Certifique-se de ter uma imagem de logo em sua pasta src
import art1 from '../../../assets/images/img/1.png';
import art2 from '../../../assets/images/img/2.png';
import art3 from '../../../assets/images/img/3.png';

const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Search } = Input;

const App = () => {
  const navigate = useNavigate(); // Usando o hook useNavigate
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await api.get(`/api/public/properties?search=${searchTerm}`); // Usando a nova rota pública com busca
        setProperties(response.data.properties);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar imóveis:', error);
        message.error('Erro ao buscar imóveis');
        setLoading(false);
      }
    };

    fetchProperties();
  }, [searchTerm]);

  const handleLoginClick = () => {
    navigate('/login'); // Redirecionando para a página de login
  };

  const handleViewProperty = (property) => {
    setSelectedProperty(property);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedProperty(null);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <img src={logo} alt="Minha Imobiliária" />
        </div>
        <Button className="login-button" type="primary" onClick={handleLoginClick}>Entrar</Button>
      </Header>
      <Content style={{ padding: '0 50px', marginTop: 64 }}>
        <div className="hero-section">
          <Carousel autoplay>
            <div>
              <img src={art1} alt="Banner 1" />
            </div>
            <div>
              <img src={art2} alt="Banner 2" />
            </div>
            <div>
              <img src={art3} alt="Banner 3" />
            </div>
          </Carousel>
        </div>
        <div className="search-bar-wrapper">
          <div className="search-bar">
            <Search
              placeholder="Buscar imóveis"
              enterButton
              onSearch={(value) => setSearchTerm(value)}
              style={{ marginBottom: 20 }}
            />
          </div>
        </div>
        <div className="site-layout-content">
          <h2>Lista de imóveis que lhe podem interessar</h2>
          <Row gutter={[16, 16]}>
            {loading ? (
              <div>Carregando...</div>
            ) : (
              properties.map(property => (
                <Col xs={24} sm={12} md={8} key={property._id}>
                  <Card
                    hoverable
                    cover={<img alt={property.description} src={property.images.length > 0 ? property.images[0] : 'https://via.placeholder.com/300'} />}
                    actions={[
                      <Button type="primary" onClick={() => handleViewProperty(property)}>
                        Visualizar
                      </Button>
                    ]}
                  >
                    <Card.Meta title={property.type} description={formatCurrency(property.value)} />
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', backgroundColor: '#f0f2f5' }}>
        <Row>
          <Col xs={24} sm={12} md={8}>
            <img src={logo} alt="Minha Imobiliária" style={{ maxHeight: '64px' }} />
            <p>Endereço: Rua Exemplo, 123, Cidade, Estado</p>
            <p>Telefone: (00) 1234-5678</p>
            <p>Email: contato@minhaimobiliaria.com</p>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <h3>Horário de Funcionamento</h3>
            <p>Segunda a Sexta: 9h - 18h</p>
            <p>Sábado: 9h - 13h</p>
            <p>Domingo: Fechado</p>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <h3>Siga-nos</h3>
            <p>Facebook | Instagram | Twitter</p>
          </Col>
        </Row>
        <p style={{ marginTop: '20px' }}>© 2024 Minha Imobiliária. Todos os direitos reservados.</p>
      </Footer>
      {selectedProperty && (
        <Modal
          title={selectedProperty.type}
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          <Carousel autoplay>
            {selectedProperty.images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Imagem ${index + 1}`} style={{ width: '100%' }} />
              </div>
            ))}
          </Carousel>
          <p><strong>Descrição:</strong> {selectedProperty.description}</p>
          <p><strong>Preço:</strong> {formatCurrency(selectedProperty.value)}</p>
          <p><strong>Contato:</strong> {selectedProperty.ownerName} - {selectedProperty.ownerPhone}</p>
          <Button
            type="primary"
            icon={<i className="fab fa-whatsapp" />}
            onClick={() => window.open(`https://wa.me/${selectedProperty.ownerPhone}`, '_blank')}
          >
            Contatar pelo WhatsApp
          </Button>
        </Modal>
      )}
    </Layout>
  );
};

export default App;
